import React, { useContext, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setAlert } from "../../actions/alerts";

// context
import { FormContext } from "../../components/Providers/FormProvider/FormProvider";
import Alert from "../../types/models/Alert";
import { useTranslation } from "react-i18next";
import { AppState } from "../../store";
import { checkWhtEventAlreadyAttended } from "../../actions/applications";
import Dialog from "@material-ui/core/Dialog/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle/DialogTitle";
import PxButton from "../../components/Buttons/PxButton";
import DialogActions from "@material-ui/core/DialogActions/DialogActions";
import DialogContentText from "@material-ui/core/DialogContentText/DialogContentText";
import FormView from "../../views/form/FormView";
import BusinessCard from "../../types/models/BusinessCard";

const ApplicationForm = () => {
  const {
    subEvent,
    submitForm,
    formData,
    adFormData,
    setFormData,
    businessCardList,
    businessCardId,
    checkOnlyEng,
  } = useContext(FormContext);
  const dispatch = useDispatch();
  const [t] = useTranslation("lang", { useSuspense: false });
  const [activeStep, setActiveStep] = React.useState(0); // form regit step state
  const history = useHistory();
  const [open, setOpen] = useState(true);
  const steps = [
    t("form.companyInfo"),
    t("form.additionalInfo"),
    t("form.review"),
  ]; // 참가양식 단계
  const { isAuthenticated } = useSelector((state: AppState) => state.users);

  const checkUserEventAttend = async () => {
    const subEventId = history.location.pathname.split("/")[3];
    let attendeeType: string;
    // 어떤 그룹의 참가신청서인지 param값으로 넘겨주기 위해 pathName 확인
    const pathName = history.location.pathname;
    if (pathName.includes("sellerPartiForm")) {
      attendeeType = "seller";
    } else {
      attendeeType = "buyer";
    }
    // 이미 참여한 이벤트인지 확인
    const result: any = await dispatch(
      checkWhtEventAlreadyAttended(subEventId, attendeeType, history)
    );

    // result === true -> 이미 참여한 이벤트
    if (result === true) {
      history.push(`/subEvent/${subEventId}/meeting`);
    }
  };

  useEffect(() => {
    if (activeStep === 2) {
      window.scrollTo(0, 0);
    }
  }, [activeStep]);

  // url로 참가양식의 제목 설정
  const setFormTitle = () => {
    const formName = history.location.pathname.split("/")[4];
    switch (formName) {
      case "sellerPartiForm":
        return `${subEvent?.sgroupName} ${t("form.partiForm")}`;
      case "buyerPartiForm":
        return `${subEvent?.bgroupName} ${t("form.partiForm")}`;
      case "boothPartiForm":
        return t("form.boothPartiForm");
      case "visitorPartiForm":
        return t("form.visitorPartiForm");
    }
  };

  // 참가양식 다음단계 버튼 (기본정보 및 추가정보 validation check (Notion 확인))
  const handleNext = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    event.preventDefault();
    const companyInfoValidationResult: boolean[] = [];
    let whtNextPage = true;
    // 기본정보 입력값 validation
    if (activeStep === 0) {
      const businessCard = businessCardList[businessCardId];
      // 명함정보 입력값 validation
      subEvent?.counselFormOnlyEng == "Y" &&
        Object.keys(businessCard).forEach((key: string) => {
          const value = businessCard[key as keyof BusinessCard];
          if (value && checkOnlyEng(value.toString())) whtNextPage = false;
        });
      if (businessCardList[businessCardId].company === "") {
        dispatch(
          setAlert({
            id: "businessCard-blank",
            msg: t("businessCardValidation.company"),
            alertType: "warning",
          })
        );
        whtNextPage = false;
      } else if (businessCardList[businessCardId].name === "") {
        dispatch(
          setAlert({
            id: "businessCard-blank",
            msg: t("businessCardValidation.name"),
            alertType: "warning",
          })
        );
        whtNextPage = false;
      } else if (businessCardList[businessCardId].email === "") {
        dispatch(
          setAlert({
            id: "businessCard-blank",
            msg: t("businessCardValidation.email"),
            alertType: "warning",
          })
        );
        whtNextPage = false;
      } else if (businessCardList[businessCardId].phoneNumber === "") {
        dispatch(
          setAlert({
            id: "businessCard-blank",
            msg: t("businessCardValidation.phoneNumber"),
            alertType: "warning",
          })
        );
        whtNextPage = false;
      } else if (businessCardList[businessCardId].company.length > 45) {
        dispatch(
          setAlert({
            id: "businessCard-blank",
            msg: t("businessCardValidation.companyVaildation"),
            alertType: "warning",
          })
        );
        whtNextPage = false;
      }
      // 이메일 형식 체크 (@, . 포함 및 . 뒤에 2자 이상 글자)
      // if (
      //   /^\w+([_0-9a-zA-Z\.-][_0-9a-zA-Z\.-]*@[_0-9a-zA-Z-]+(\.[_0-9a-zA-Z\--]+){1,2})+$/.test(
      //     businessCardList[businessCardId].email
      //   ) === false
      // ) {
      //   dispatch(
      //     setAlert({
      //       id: "businessCard-blank",
      //       msg: t("businessCardValidation.emailValid"),
      //       alertType: "warning",
      //     })
      //   );
      //   whtNextPage = false;
      // }

      // 기본정보 입력값 validation
      if (whtNextPage === true) {
        formData?.formIds.forEach((formId, index) => {
          // 일반 필수값 validation check
          if (formData.formData[formId].requireCheck === "Y") {
            switch (formData.formData[formId].type) {
              case "many":
                if (
                  formData.formData[formId].checkBoxContent === undefined ||
                  formData.formData[formId].checkBoxContent?.length === 0
                ) {
                  companyInfoValidationResult.splice(index, 1, false);
                } else {
                  companyInfoValidationResult.splice(index, 1, true);
                }
                break;
              case "single":
                if (formData.formData[formId].radioContent === undefined) {
                  companyInfoValidationResult.splice(index, 1, false);
                } else {
                  companyInfoValidationResult.splice(index, 1, true);
                }
                break;
              case "file":
                if (
                  formData.formData[formId].fileContent === undefined ||
                  formData.formData[formId].fileContent?.fileId === undefined
                ) {
                  companyInfoValidationResult.splice(index, 1, false);
                } else {
                  companyInfoValidationResult.splice(index, 1, true);
                }
                break;
              default:
                if (
                  formData.formData[formId].content === undefined ||
                  formData.formData[formId].content === ""
                ) {
                  companyInfoValidationResult.splice(index, 1, false);
                } else {
                  companyInfoValidationResult.splice(index, 1, true);
                }
                break;
            }
          } else {
            // 상담회 필수값 validation check (기업명, 카테고리, 키워드)
            if (subEvent?.category === "consulting") {
              switch (index) {
                case 0:
                  if (
                    formData.formData[formId].title === "소속명" ||
                    formData.formData[formId].title === "Organization name"
                  ) {
                    setFormData((prevState) => {
                      Object.assign(prevState.formData[formId], {
                        content: businessCardList[businessCardId]
                          .company as string,
                      });
                      return prevState;
                    });
                    companyInfoValidationResult.splice(index, 1, true);
                  }
                  break;
                case 1:
                  if (
                    formData.formData[formId].keywordContent ||
                    (formData?.formData[formId].keywordContent &&
                      formData.formData[formId].keywordContent!.toString()
                        .length >= 255) ||
                    !formData.formData[formId].selectContent
                  ) {
                    companyInfoValidationResult.splice(index, 1, false);
                    console.log(
                      `form keyword Content : `,
                      formData.formData[formId]
                    );
                  } else {
                    companyInfoValidationResult.splice(index, 1, true);
                  }
                  break;
                case 2:
                  if (
                    formData.formData[formId].keywordContent === undefined ||
                    formData.formData[formId].keywordContent === ""
                  ) {
                    companyInfoValidationResult.splice(index, 1, false);
                  } else {
                    companyInfoValidationResult.splice(index, 1, true);
                  }
                  break;
              }
            }
          }
        });

        // 기본정보 입력값 validation 후 결과출력
        const result: number = companyInfoValidationResult.indexOf(false);

        if (result === -1) {
          whtNextPage = true;
        } else {
          whtNextPage = false;
          let msg = "";
          if (subEvent?.category !== "presentation") {
            if (result === 0) {
              msg = t("formValidation.companyName");
            } else if (result === 1) {
              msg = t("formValidation.category");
            } else if (result === 2) {
              msg = t("formValidation.keywords");
            } else {
              msg = t("formValidation.required");
            }
          } else {
            msg = t("formValidation.required");
          }

          const alert: Alert = {
            id: "blank-error",
            msg: msg,
            alertType: "warning",
          };
          dispatch(setAlert(alert));
        }
      }
    }

    // 추가정보 입력값 validation
    if (activeStep === 1) {
      const alert: Alert = {
        id: "blank-error",
        msg: t("form.pleaseRequired"),
        alertType: "warning",
      };
      adFormData?.formIds.forEach((formId) => {
        if (adFormData.formData[formId].requireCheck === "Y") {
          switch (adFormData.formData[formId].type) {
            case "many":
              if (adFormData.formData[formId].checkBoxContent === undefined) {
                dispatch(setAlert(alert));
                whtNextPage = false;
              } else {
                whtNextPage = true;
              }
              break;
            case "single":
              if (adFormData.formData[formId].radioContent === undefined) {
                dispatch(setAlert(alert));
                whtNextPage = false;
              } else {
                whtNextPage = true;
              }
              break;
            case "file":
              if (adFormData.formData[formId].fileContent === undefined) {
                dispatch(setAlert(alert));
                whtNextPage = false;
              } else {
                whtNextPage = true;
              }
              break;
            default:
              if (adFormData.formData[formId].content === undefined) {
                dispatch(setAlert(alert));
                whtNextPage = false;
              } else {
                whtNextPage = true;
              }
              break;
          }
        }
      });
    }

    // 입력값 validation 후 결과출력
    if (whtNextPage === true) {
      if (adFormData === null || adFormData?.formIds.length === 0) {
        // 추가정보 양식이 없을경우
        setActiveStep(2);
      } else {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
      }
    }
  };

  // 참가양식 이전단계 버튼
  const handleBack = () => {
    // 추가정보 양식이 없을경우
    if (
      activeStep === 2 &&
      (adFormData?.formIds.length === 0 || adFormData === null)
    ) {
      setActiveStep(0);
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
    }
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleGoToLogin = () => {
    history.push("/auth/login");
  };

  return subEvent ? (
    <FormView
      subEvent={subEvent}
      steps={steps}
      activeStep={activeStep}
      adFormData={adFormData}
      businessCardList={businessCardList}
      businessCardId={businessCardId}
      setFormTitle={setFormTitle}
      handleBack={handleBack}
      handleNext={handleNext}
      submitForm={submitForm}
    />
  ) : null;
};

export default ApplicationForm;
