import React, { useCallback, useEffect, useState } from "react";
import toInteger from "lodash/toInteger";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { useMyInfoContext } from "./useMyInfoContext";

import { getSignedRequest } from "../../../../actions/files";

import PxButton from "../../../../components/Buttons/PxButton";
import ShowFile from "../../../../components/File/ShowFile";
import PxGridContainer from "../../../../components/Grid/PxGridContainer";
import PxGridItem from "../../../../components/Grid/PxGridItem";
import PxOutlinedTxtField from "../../../../components/Inputs/PxOutlinedTxtField";
import ButtonTypo from "../../../../components/Typhography/ButtonTypo";
import TranslatedFormItem from "../../../form/TranslatedFormItem";

import { ApplicationFormAnswers } from "../../../../types/models/Application";
import { AutonoForm, ProductProperty } from "../../../../types/models/Form";

import { AppState } from "../../../../store";

import styled from "styled-components";

import Button from "@material-ui/core/Button";
import Chip from "@material-ui/core/Chip";
import FormControl from "@material-ui/core/FormControl";
import FormGroup from "@material-ui/core/FormGroup";
import RadioGroup from "@material-ui/core/RadioGroup";
import Autocomplete from "@material-ui/lab/Autocomplete";
import SubHeadingTypo from "../../../../components/Typhography/SubHeadingTypo";
import { getSubEventApplicationKeywords } from "../../../../actions/applications";
import OutlinedTxtField from "../../../../components/Inputs/OutlinedTxtField";

interface ContentFormItemProps {
  contentFormData: AutonoForm; // 신청서 양식 데이터
  modify: boolean; // 보기 or 수정 상태
  whtAdditional: boolean; // 일반답변 or 추가양식 답변 구분
  additionOrder?: number; // 추가정보 순서
  applicationFormAnswer: ApplicationFormAnswers; // 자율양식 신청서 답변
  applicationManyFormAnswers: ApplicationFormAnswers[]; // 복수선택 신청서 답변
}

type ProductInfoChangeType = "title" | "explain" | "link";

const ContentFormItem: React.FC<ContentFormItemProps> = ({
  contentFormData,
  modify,
  whtAdditional,
  additionOrder,
  applicationFormAnswer,
  applicationManyFormAnswers,
}) => {
  const {
    id,
    type,
    title,
    explain,
    explainCheck,
    requireCheck,
    selectContent,
  } = contentFormData;
  const dispatch = useDispatch();
  const [t, i18n] = useTranslation("lang", { useSuspense: false });
  const params: { id: string } = useParams(); // id: subEvent id
  const { modifyContentAnswers, formId, modifyManyContentAnswers } =
    useMyInfoContext();

  const form = useSelector((state: AppState) => state.forms.content);

  // 자율양식 신청서 데이터
  const [textContent, setTextContent] = useState<string>(""); // 신청서 url, short, long type data
  const [keywordData, setKeywordData] = useState<string[]>([]); // 신청서 keyword data string 배열 data
  const [keywordOptionData, setKeywordOptionData] = useState<string[]>([]); // 신청서 keyword data options string 배열 data
  const [keywordInputValue, setKeywordInputValue] = useState<string>(""); // 키워드필드의 input 값
  const [radioValue, setRadioValue] = useState("");
  const [checkBoxValue, setCheckBoxValue] = useState<{
    [key: string]: boolean;
  }>({});
  const [fileNameValue, setFileNameValue] = useState<string | undefined>();
  const [productInfo, setProductInfo] = useState<ProductProperty>({
    title: "",
    explain: "",
    productImg: { fileId: 0, name: "", url: "" },
    link: "",
  });

  useEffect(() => {
    if (applicationFormAnswer !== null && applicationFormAnswer !== undefined) {
      switch (type) {
        case "keyword": {
          if (applicationFormAnswer.content !== undefined) {
            setKeywordData(applicationFormAnswer.content.split(","));
            getSubEventKeywords();
          }
          break;
        }
        case "category": {
          if (
            applicationFormAnswer.content !== null &&
            applicationFormAnswer.content !== undefined
          ) {
            setRadioValue(
              applicationFormAnswer.answerUuid +
                "[S]" +
                applicationFormAnswer.content
            );
          }
          break;
        }
        case "single": {
          if (
            applicationFormAnswer !== undefined &&
            applicationFormAnswer.content !== null
          ) {
            setRadioValue(
              applicationFormAnswer.answerUuid +
                "[S]" +
                applicationFormAnswer.content
            );
          }
          break;
        }
        case "many": {
          let defaultSet = {};
          let checkBoxContentText = "";

          if (applicationManyFormAnswers.length !== 0) {
            applicationManyFormAnswers.forEach((value) => {
              checkBoxContentText +=
                value.answerUuid + "[S]" + value.content + "&&";
            });

            selectContent!.formIds.forEach((formId) => {
              const key = `${formId}[S]${selectContent!.formData[formId]
                .explain!}`;
              Object.assign(defaultSet, {
                [key]: checkBoxContentText.indexOf(key) > -1 ? true : false,
              });
            });
            setCheckBoxValue(defaultSet);
          }
          break;
        }
        case "file": {
          if (applicationFormAnswer !== undefined) {
            setFileNameValue(applicationFormAnswer.content);
          }
          break;
        }
        case "product": {
          if (applicationFormAnswer !== undefined) {
            setProductInfo({
              title: applicationFormAnswer.productName as string,
              explain: applicationFormAnswer.productExplain as string,
              productImg: {
                ...productInfo.productImg,
                fileId: applicationFormAnswer.productImg as number,
              },
              link: applicationFormAnswer.productLink as string,
            });
          }
          break;
        }
        default: {
          if (applicationFormAnswer !== undefined) {
            setTextContent(applicationFormAnswer.content!);
          }
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [type, applicationFormAnswer, modify]);

  // 서브이벤트 참가신청 시 다른사람이 먼저 등록한 키워드 값들을 option으로 보여주기 위함
  const getSubEventKeywords = useCallback(async () => {
    if (params.id !== undefined) {
      const keywords: string[] = await getSubEventApplicationKeywords(
        params.id
      );

      setKeywordOptionData(keywords);
    }
  }, [params.id]);

  // 단일선택 답변 onChange method
  const handleRadioOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRadioValue((event.target as HTMLInputElement).value);
    modifyContentAnswers(
      applicationFormAnswer,
      event.target.value,
      whtAdditional,
      additionOrder
    );
  };

  // 단문 && 장문 && url onChange method
  const handleTextInputOnChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    console.log(`??`);
    setTextContent((event.target as HTMLInputElement).value);
    modifyContentAnswers(
      applicationFormAnswer,
      event.target.value,
      whtAdditional,
      additionOrder
    );
  };

  // 키워드 onChange method
  const handleKeywordOnChange = (
    event: React.ChangeEvent<{}>,
    value: string[]
  ) => {
    event.preventDefault();
    modifyContentAnswers(
      applicationFormAnswer,
      value.toString(),
      whtAdditional,
      additionOrder
    );
    setKeywordInputValue("");
  };

  const handleProductInfoOnChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    type: ProductInfoChangeType
  ) => {
    switch (type) {
      case "title":
        setProductInfo({
          ...(productInfo as ProductProperty),
          title: event.target.value as string,
        });
        modifyContentAnswers(
          applicationFormAnswer,
          { ...productInfo, title: event.target.value },
          whtAdditional,
          additionOrder
        );
        break;
      case "explain":
        setProductInfo({
          ...(productInfo as ProductProperty),
          explain: event.target.value as string,
        });
        modifyContentAnswers(
          applicationFormAnswer,
          { ...productInfo, explain: event.target.value },
          whtAdditional,
          additionOrder
        );
        break;
      case "link":
        setProductInfo({
          ...(productInfo as ProductProperty),
          link: event.target.value as string,
        });
        modifyContentAnswers(
          applicationFormAnswer,
          { ...productInfo, link: event.target.value },
          whtAdditional,
          additionOrder
        );
        break;
    }
  };

  const handleCheckBoxOnChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => {
    e.preventDefault();
    if (applicationFormAnswer !== undefined) {
    } else {
    }
    setCheckBoxValue((prevCheckBoxValue) => {
      Object.assign(prevCheckBoxValue, {
        [`${e.target.name}`]: checked,
      });
      return { ...prevCheckBoxValue };
    });

    const newApplicationFormAnswers: ApplicationFormAnswers[] = [];
    Object.entries(checkBoxValue).forEach((value) => {
      if (value[1] === true) {
        const splitedKey = value[0].split("[S]"); // 0 : answerUuid, 1: content
        const newApplicationFormAnswer: ApplicationFormAnswers = {
          formId: formId!,
          answerUuid: splitedKey[0],
          title: contentFormData.title!,
          type: type!,
          uuid: contentFormData.id,
          repeatInfo: whtAdditional ? "Y" : "N",
          content: splitedKey[1],
          additionOrder: additionOrder,
        };
        newApplicationFormAnswers.push(newApplicationFormAnswer);
      }
    });

    modifyManyContentAnswers(
      contentFormData.id,
      additionOrder,
      whtAdditional,
      newApplicationFormAnswers
    );
  };
  const handleProductImgDelete = () => {
    setProductInfo({
      ...productInfo,
      productImg: { ...productInfo.productImg, fileId: 0 },
    });
  };

  // 파일 삭제 method
  const handleFileDelete = () => {
    setFileNameValue(undefined);
  };

  const urlClickEvent = (url: string) => {
    if (url !== "") {
      if (!url.includes("https://") && !url.includes("http://")) {
        window.open("http://" + url);
      } else {
        window.open(url);
      }
    }
  };

  // 파일 등록 method
  const handleFileAdd = async (
    e: React.ChangeEvent<{
      files: any;
      name?: string | undefined;
    }>,
    isProduct?: boolean
  ) => {
    e.preventDefault();

    const file = e.target.files[0];
    const fileParts = file.name.split(".");
    const originalFileName = fileParts[0];
    const timestamp = new Date().getTime();
    const fileType = fileParts[1];
    const fileName = originalFileName + "&&" + timestamp + "." + fileType;
    const folderPath = `event/${params.id}/`;
    const fileSize = file.size;

    const result: any = await dispatch(
      getSignedRequest(file, fileName, fileType, folderPath, fileSize)
    );

    if (result !== false && result !== undefined) {
      if (isProduct) {
        setProductInfo({
          ...productInfo,
          productImg: { ...productInfo.productImg, fileId: result.id },
        });
        modifyContentAnswers(
          applicationFormAnswer,
          {
            ...productInfo,
            productImg: { ...productInfo.productImg, fileId: result.id },
          },
          whtAdditional,
          additionOrder
        );
      } else {
        setFileNameValue(result.id.toString());
        modifyContentAnswers(
          applicationFormAnswer,
          result.id.toString(),
          whtAdditional,
          additionOrder
        );
      }
    }
  };

  return (
    <PxGridContainer className="py-3" direction="column">
      {/* 질문 */}
      <PxGridItem>
        <SubHeadingTypo fontweight="bold">
          <TranslatedFormItem
            type="input"
            content={`${title} ${requireCheck === "Y" ? " (필수)" : ""}`}
            name={id}
            fullWidth
            fontsize={15}
            readOnly={true}
            onlyEng={false}
            fontweight="bold"
            link={modify}
          />
        </SubHeadingTypo>
      </PxGridItem>
      {/* ///////////////// */}

      {/* 설명 */}
      {explainCheck === "Y" && (
        <PxGridItem>
          <TranslatedFormItem
            type="string"
            content={explain!}
            name={id}
            fullWidth
            fontsize={14}
            fontweight="bold"
            readOnly
            onlyEng={false}
            link={modify}
          />
        </PxGridItem>
      )}
      {/* //////////////////// */}

      {/* 장문, 단문, url */}
      {(type === "long" || type === "short" || type === "url") && (
        <PxGridItem>
          <PxOutlinedTxtField
            name={id}
            value={() => {
              console.log(`textContent : ` + textContent);
              return textContent === null ? "" : textContent!;
            }}
            disabled={title === "기업명" || title === "소속명" || !modify}
            inputpadding={type === "long" ? 0 : 10}
            multiline={type === "long" ? true : false}
            rows={2}
            fullWidth
            placeholder={t("form.pleaseEnter")}
            fontsize={14}
            onChange={handleTextInputOnChange}
          />
        </PxGridItem>
      )}
      {/* ///////////////////////////// */}

      {/* 키워드 - 태그들의 값이 일반보기 페이지와 수정페이지간의 변경이 unControlled component에서는 원활히 이루어지지 않아 어쩔 수 없이 일반보기와 수정일때를 분리하여 keyword 양식이 나오도록 함 */}
      {/* 일반보기 */}
      {type === "keyword" && modify === false && (
        <PxGridItem>
          <Autocomplete
            multiple
            freeSolo
            options={keywordOptionData.map((keyword) => keyword)}
            disabled={true}
            id="keyword-list"
            inputValue={keywordInputValue}
            defaultValue={keywordData.map<string>((keyword) => keyword)}
            renderTags={(value: string[], getTagProps) => {
              // 일반 보기페이지와 수정페이지를 구분해서 tag들을 보여줌
              const keywords = applicationFormAnswer.content?.split(",");
              return keywords!.map((option: string, index: number) => (
                <Chip
                  key={index}
                  variant="outlined"
                  label={option}
                  {...getTagProps({ index })}
                />
              ));
            }}
            renderInput={(params) => (
              <PxOutlinedTxtField
                {...params}
                inputProps={{ ...params.inputProps, style: { padding: 0 } }}
                fullWidth
                disabled={!modify}
                placeholder={t("form.pleaseKeywords")} // 등록정보 보기 페이지에서는 안내문구 필요x
                fontsize={14}
              />
            )}
          />
        </PxGridItem>
      )}
      {/* 키워드 수정 */}
      {type === "keyword" && modify === true && (
        <PxGridItem>
          <Autocomplete
            multiple
            freeSolo
            options={keywordOptionData.map((keyword) => keyword)}
            disabled={false}
            id="keyword-list"
            onChange={handleKeywordOnChange}
            onInputChange={(event, value) => {
              if (value.length <= 20)
                if (/\s/.test(value) === false) {
                  setKeywordInputValue(value.toLocaleLowerCase());
                }
            }}
            inputValue={keywordInputValue}
            defaultValue={keywordData.map<string>((keyword) => keyword)}
            renderTags={(value: string[], getTagProps) => {
              // 일반 보기페이지와 수정페이지를 구분해서 tag들을 보여줌
              return value.map((option: string, index: number) => (
                <Chip
                  key={index}
                  variant="outlined"
                  label={option}
                  {...getTagProps({ index })}
                />
              ));
            }}
            renderInput={(params) => (
              <PxOutlinedTxtField
                {...params}
                inputProps={{ ...params.inputProps, style: { padding: 0 } }}
                fullWidth
                disabled={!modify}
                placeholder={""} // 등록정보 보기 페이지에서는 안내문구 필요x
                fontsize={14}
              />
            )}
          />
        </PxGridItem>
      )}
      {/* /////////////////////////////////////////////// */}

      {/* 카테고리 or 단일선택 */}
      {(type === "single" || type === "category") && (
        <PxGridItem>
          <FormControl>
            {/* radio button value는  */}
            <RadioGroup value={radioValue} onChange={handleRadioOnChange}>
              {selectContent!.formIds.map((formId, index) => {
                // 등록정보 보기 페이지에서는 선택한 답변만 나와야함
                // modify => false: 일반 등록정보 보기, false: 수정 페이지
                if (modify === false) {
                  if (
                    applicationFormAnswer !== undefined &&
                    applicationFormAnswer.answerUuid ===
                      selectContent!.formData[formId].id
                  ) {
                    return (
                      <TranslatedFormItem
                        key={index}
                        onlyEng={false}
                        type="radio"
                        content={selectContent!.formData[formId].explain!}
                        disabled={!modify}
                        value={`${formId}[S]${selectContent!.formData[formId]
                          .explain!}`}
                        link={modify}
                      />
                    );
                  }
                } else {
                  return (
                    <TranslatedFormItem
                      key={index}
                      onlyEng={false}
                      type="radio"
                      content={selectContent!.formData[formId].explain!}
                      disabled={!modify}
                      value={`${formId}[S]${selectContent!.formData[formId]
                        .explain!}`}
                      link={modify}
                    />
                  );
                }
                return null;
              })}
            </RadioGroup>
          </FormControl>
        </PxGridItem>
      )}
      {/* /////////////////////////////////// */}

      {type === "product" && productInfo && (
        <Container>
          <TranslatedFormItem
            type="input"
            name="productName"
            content="제품명"
            fullWidth
            link={true}
            fontsize={15}
            readOnly={true}
            fontweight="bold"
            onlyEng={false}
          />
          <OutlinedTxtField
            multiline={false}
            disabled={!modify}
            value={productInfo.title}
            inputProps={{
              style: { cursor: "default" },
            }}
            rows={2}
            onChange={(e) => {
              handleProductInfoOnChange(
                e as React.ChangeEvent<HTMLInputElement>,
                "title"
              );
            }}
            fullWidth
          />
          <TranslatedFormItem
            type="input"
            name="productName"
            content="제품 설명"
            fullWidth
            link={true}
            fontsize={15}
            readOnly={true}
            fontweight="bold"
            onlyEng={false}
          />
          <OutlinedTxtField
            multiline={true}
            disabled={!modify}
            value={productInfo.explain}
            inputProps={{
              style: { cursor: "default" },
            }}
            rows={10}
            onChange={(e) => {
              handleProductInfoOnChange(
                e as React.ChangeEvent<HTMLInputElement>,
                "explain"
              );
            }}
            fullWidth
          />
          <TranslatedFormItem
            type="input"
            name="productName"
            content="제품 이미지"
            fullWidth
            link={true}
            fontsize={15}
            readOnly={true}
            fontweight="bold"
            onlyEng={false}
          />
          {productInfo.productImg.fileId !== 0 && (
            <ShowFile fileId={productInfo.productImg.fileId} />
          )}
          {productInfo.productImg.fileId !== 0 && modify === true && (
            <PxButton backgroundcolor="grey" onClick={handleProductImgDelete}>
              <ButtonTypo>삭제</ButtonTypo>
            </PxButton>
          )}
          {productInfo.productImg.fileId == 0 && modify && (
            <Button
              variant="contained"
              component="label"
              style={{
                boxShadow: "none",
                backgroundColor: "#eee",
                padding: "9px 16px",
              }}
            >
              추가
              <input
                type="file"
                style={{ display: "none" }}
                name="file1"
                onChange={(e) => {
                  handleFileAdd(e, true);
                }}
              />
            </Button>
          )}
          <TranslatedFormItem
            type="input"
            name="productName"
            content="제품 링크"
            fullWidth
            link={true}
            fontsize={15}
            readOnly={true}
            fontweight="bold"
            onlyEng={false}
          />
          <OutlinedTxtField
            multiline={false}
            disabled={!modify}
            value={productInfo.link}
            inputProps={{
              style: { cursor: "pointer" },
            }}
            rows={2}
            fullWidth
            onChange={(e) => {
              handleProductInfoOnChange(
                e as React.ChangeEvent<HTMLInputElement>,
                "link"
              );
            }}
            onClick={() => {
              !modify && urlClickEvent(productInfo.link);
            }}
          />
        </Container>
      )}
      {/* 복수선택 */}
      {type === "many" && (
        <PxGridItem>
          <FormControl>
            <FormGroup>
              {selectContent!.formIds.map((formId, index) => {
                // 등록정보 보기 페이지에서는 선택한 답변만 나와야함
                // modify => false: 일반 등록정보 보기, false: 수정 페이지
                // 등록정보 보기 페이지에서는 checkBoxValue 배열에 있는 key의 값이 true인것만 출력하도록 함
                if (modify === false) {
                  if (
                    checkBoxValue[
                      `${formId}[S]${selectContent!.formData[formId].explain!}`
                    ] === true
                  ) {
                    return (
                      <TranslatedFormItem
                        key={index}
                        onlyEng={false}
                        type="checkbox"
                        content={selectContent!.formData[formId].explain!}
                        disabled={!modify}
                        checked={
                          checkBoxValue[
                            `${formId}[S]${selectContent!.formData[formId]
                              .explain!}`
                          ]
                        }
                        name={`${formId}[S]${selectContent!.formData[formId]
                          .explain!}`}
                        link={modify}
                      />
                    );
                  }
                } else {
                  return (
                    <TranslatedFormItem
                      key={index}
                      onlyEng={false}
                      type="checkbox"
                      content={selectContent!.formData[formId].explain!}
                      disabled={!modify}
                      checked={
                        checkBoxValue[
                          `${formId}[S]${selectContent!.formData[formId]
                            .explain!}`
                        ]
                      }
                      name={`${formId}[S]${selectContent!.formData[formId]
                        .explain!}`}
                      onChange={handleCheckBoxOnChange}
                      link={modify}
                    />
                  );
                }
                return null;
              })}
            </FormGroup>
          </FormControl>
        </PxGridItem>
      )}
      {/* /////////////////////////////// */}

      {/* 파일 */}
      {type === "file" && (
        <PxGridItem>
          {fileNameValue !== undefined &&
          fileNameValue !== null &&
          fileNameValue !== "" ? (
            <>
              <ShowFile fileId={toInteger(fileNameValue)} />
              {modify === true && (
                <PxButton backgroundcolor="grey" onClick={handleFileDelete}>
                  <ButtonTypo>{t("form.delete")}</ButtonTypo>
                </PxButton>
              )}
            </>
          ) : (
            <>
              {modify === true && (
                <Button
                  variant="contained"
                  component="label"
                  style={{
                    boxShadow: "none",
                    backgroundColor: "#eee",
                    padding: "9px 16px",
                  }}
                >
                  {t("form.add")}
                  <input
                    type="file"
                    style={{ display: "none" }}
                    name="file1"
                    onChange={handleFileAdd}
                  />
                </Button>
              )}
            </>
          )}
        </PxGridItem>
      )}
      {/* ////////////////////////////////////// */}
    </PxGridContainer>
  );
};

export default ContentFormItem;

const Container = styled.div`
  border: 1px solid #6c757d;
  border-radius: 7px;
  padding: 10px;
`;
